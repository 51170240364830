import { createSearchParams, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { URLSearchParamsInit } from 'react-router-dom/dist/dom';

import { GetCallsHistoryParams } from '../../../api/callsHistory/types';

export const useFiltersSearchParams = () => {
  const navigate = useNavigate();

  const updateSearchParams = useCallback((params: GetCallsHistoryParams) => {
    const newSearchParams: URLSearchParamsInit = {
      take: params.take.toString(),
      skip: params.skip.toString(),
      sortBy: params.sortBy,
      order: params.order,
    };

    if (params.search) {
      newSearchParams.search = params.search;
    }

    if (params.searchBy) {
      newSearchParams.searchBy = params.searchBy;
    }

    if (params.callDirection !== undefined) {
      newSearchParams.callDirection = params.callDirection.toString();
    }

    if (params.campaign !== undefined) {
      newSearchParams.campaign = params.campaign.toString();
    }

    if (params.callStatus) {
      newSearchParams.callStatus = params.callStatus;
    }

    if (params.customer !== undefined) {
      newSearchParams.customer = params.customer.toString();
    }

    if (params.user !== undefined) {
      newSearchParams.user = params.user.toString();
    }

    if (params.callStartedFrom) {
      newSearchParams.callStartedFrom = params.callStartedFrom;
    }

    if (params.callStartedTo) {
      newSearchParams.callStartedTo = params.callStartedTo;
    }

    if (params.callEndedFrom) {
      newSearchParams.callEndedFrom = params.callEndedFrom;
    }

    if (params.callEndedTo) {
      newSearchParams.callEndedTo = params.callEndedTo;
    }

    navigate(`?${createSearchParams(newSearchParams)}`);
  }, [navigate]);

  return {
    updateSearchParams,
  };
};

export default useFiltersSearchParams;
