import { useMemo } from 'react';
import { Popover, Typography } from 'antd';
import cn from 'classnames';
import { parseTranscript } from '@marrlab-app-shared/utils/transcript';

import { CallHistory } from '../../../../api/callsHistory/types';

import './index.scss';

interface TranscriptCellProps {
  value: CallHistory['callTranscriptJson'];
}

const TranscriptCell = ({ value: cellValue }: TranscriptCellProps) => {
  const transcript = useMemo(() => parseTranscript(cellValue), [cellValue]);

  if (!transcript?.length) {
    return null;
  }

  return (
    <Popover
      overlayClassName={cn('transcript-cell-popover', { 'with-scroll-bar': transcript.length > 4 })}
      destroyTooltipOnHide
      content={(
        <>
          {transcript.map(({ role, content }, index) => (
            <Typography.Text
              className="whitespace-pre-wrap"
              // eslint-disable-next-line react/no-array-index-key
              key={`${role}-${content}-${index}`}
            >
              <Typography.Text strong className="capitalize">
                {role}
                :
              </Typography.Text>
              {' '}
              <Typography.Text>{content}</Typography.Text>
              <br />
            </Typography.Text>
          ))}
        </>
      )}
      title="Transcript"
    >
      <Typography.Text
        ellipsis
        className="w-xs"
        copyable={{
          tooltips: ['Copy raw value'],
          text: JSON.stringify(cellValue),
        }}
      >
        <Typography.Text strong className="capitalize">
          {transcript[0].role}
          :
          {' '}
        </Typography.Text>
        {transcript[0].content}
      </Typography.Text>
    </Popover>
  );
};

export default TranscriptCell;
