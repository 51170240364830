import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { Page } from '@marrlab-app-shared/types';

import { getCallsHistory, getCallsHistoryByCustomerId } from '../../../api/callsHistory';
import { useFiltersSearchParams } from './useFiltersSearchParams';
import { CallHistory, GetCallsHistoryParams } from '../../../api/callsHistory/types';
import { useCustomerIdFromOrganization } from '../../../hooks/customer/useCustomerIdFromOrganization';

const useCallsHistoryData = ({
  skip,
  take,
  sortBy,
  order,
  search,
  callDirection,
  campaign,
  callStatus,
  customer,
  user,
  callStartedFrom,
  callStartedTo,
  callEndedFrom,
  callEndedTo,
  searchBy,
}: GetCallsHistoryParams) => {
  const { updateSearchParams } = useFiltersSearchParams();
  const { customerId, onlyCertainCustomer } = useCustomerIdFromOrganization();

  const [data, setData] = useState<Page<CallHistory> | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchCallsHistory = useCallback(async () => {
    setLoading(true);
    try {
      if (onlyCertainCustomer && !customerId) {
        return;
      }

      const params: GetCallsHistoryParams = {
        skip,
        take,
        sortBy,
        order,
        search: search.trim(),
        callDirection,
        campaign,
        callStatus,
        user,
        callStartedFrom,
        callStartedTo,
        callEndedFrom,
        callEndedTo,
        searchBy,
      };

      if (!onlyCertainCustomer) {
        params.customer = customer;
      }

      updateSearchParams(params);

      const response = await (onlyCertainCustomer
        ? getCallsHistoryByCustomerId(params, customerId)
        : getCallsHistory(params));

      setData(response.data);
    } catch (error) {
      console.error(error);
      message.open({ content: (error as { message: string }).message, type: 'error', duration: 10 });
    }

    setLoading(false);
  }, [
    callEndedFrom,
    callEndedTo,
    callStartedFrom,
    callStartedTo,
    customer,
    user,
    callStatus,
    campaign,
    callDirection,
    order,
    search,
    skip,
    sortBy,
    take,
    searchBy,
    updateSearchParams,
    onlyCertainCustomer,
    customerId,
  ]);

  useEffect(() => {
    fetchCallsHistory();
  }, [fetchCallsHistory]);

  return {
    data,
    loading,
    fetchCallsHistory,
  };
};

export default useCallsHistoryData;
