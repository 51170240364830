import { ChangeEvent, useCallback, useMemo } from 'react';
import { Input, Select, Tooltip } from 'antd';
import useCheckRole from '@marrlab-app-shared/hooks/auth';
import UserPermissions from '@marrlab-app-shared/constants/permissions';

import { FilterProps } from '../../types';

import './index.scss';

interface SearchProps {
  searchBy?: string;
  updateSearch: (search: string) => void;
  defaultSearch?: string;
  onFiltersUpdate: FilterProps['onFiltersUpdate'];
}

const searchByOptions = [
  {
    value: 'callLogId',
    label: 'Call log ID',
    availableForExternal: true,
  },
  {
    value: 'callLogUuid',
    label: 'Call log UUID',
    availableForExternal: true,
  },
  {
    value: 'callStatus',
    label: 'Call status',
    availableForExternal: true,
  },
  {
    value: 'twilioConfSid',
    label: 'Twilio conf SID',
    availableForExternal: false,
  },
  {
    value: 'operatorNumber',
    label: 'Operator number',
    availableForExternal: false,
  },
  {
    value: 'agentNumber',
    label: 'Agent number',
    availableForExternal: true,
  },
  {
    value: 'humanBackupNumber',
    label: 'Human backup number',
    availableForExternal: false,
  },
  {
    value: 'userNumber',
    label: 'User number',
    availableForExternal: true,
  },
  {
    value: 'campaign',
    label: 'Campaign name',
    availableForExternal: true,
  },
  {
    value: 'customer',
    label: 'Customer name',
    availableForExternal: true,
  },
  {
    value: 'twilioAgentCallSid',
    label: 'Twilio agent call SID',
    availableForExternal: false,
  },
  {
    value: 'twilioHumanBackupCallSid',
    label: 'Twilio human backup call SID',
    availableForExternal: false,
  },
  {
    value: 'twilioContactCallSid',
    label: 'Twilio contact call SID',
    availableForExternal: false,
  },
  {
    value: 'retellCallId',
    label: 'Retell call ID',
    availableForExternal: false,
  },
  {
    value: 'orchestratorProvider',
    label: 'Orchestrator provider ID',
    availableForExternal: false,
  },
  {
    value: 'customerCallId',
    label: 'Customer call ID',
    availableForExternal: true,
  },
];

const Search = ({
  searchBy,
  defaultSearch,
  updateSearch,
  onFiltersUpdate,
}: SearchProps) => {
  const { has } = useCheckRole();

  const canAccessInternalTools = has(UserPermissions.manageInternalTools);

  const onSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    updateSearch(event.target.value);
  }, [updateSearch]);

  const onSearchByChange = useCallback((value: string | null) => {
    onFiltersUpdate('searchBy', value ?? undefined);
  }, [onFiltersUpdate]);

  const options = useMemo(() => {
    if (canAccessInternalTools) {
      return searchByOptions;
    }

    return searchByOptions.filter((option) => option.availableForExternal);
  }, [canAccessInternalTools]);

  return (
    <Input.Search
      id="search-input"
      addonBefore={(
        <Tooltip
          placement="top"
          title='Select a specific column to search by it ("includes" creteria)'
        >
          <Select
            virtual={false}
            id="search-by-select"
            className="call-history-search-by-select"
            options={options}
            value={searchBy}
            onChange={onSearchByChange}
          />
        </Tooltip>
      )}
      className="w-prose"
      placeholder="Type to search"
      allowClear
      defaultValue={defaultSearch}
      onChange={onSearchChange}
    />
  );
};

export default Search;
