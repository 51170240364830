import { CallsHistoryFilters } from '../types';
import { CallHistory, GetCallsHistoryParams } from '../../../api/callsHistory/types';
import { SortOrder } from '../../../constants';
import { idColumnKey } from '../constants/columnsInitialState';

export const getDefaultFilters = (): CallsHistoryFilters => {
  const params = new URLSearchParams(window.location.search);

  const rawCallDirection = params.get('callDirection');

  const callDirection = rawCallDirection ? parseInt(rawCallDirection, 10) as 1 | 0 : undefined;

  return {
    callDirection,
    callEndedFrom: params.get('callEndedFrom') ?? undefined,
    callEndedTo: params.get('callEndedTo') ?? undefined,
    callStartedFrom: params.get('callStartedFrom') ?? undefined,
    callStartedTo: params.get('callStartedTo') ?? undefined,
    callStatus: params.get('callStatus') ?? undefined,
    campaign: params.get('campaign') ?? undefined,
    customer: params.get('customer') ?? undefined,
    user: params.get('user') ?? undefined,
    searchBy: params.get('user') ?? 'callLogUuid',
  };
};

export const getDefaultPagination = (): { pageSize: number, page: number } => {
  const params = new URLSearchParams(window.location.search);

  const rawTake = params.get('take');
  const rawSkip = params.get('skip');

  const take = rawTake ? parseInt(rawTake, 10) : 20;
  const skip = rawSkip ? parseInt(rawSkip, 10) : 0;

  return {
    pageSize: take,
    page: (skip / take) + 1,
  };
};

export const getDefaultSearch = (): string => {
  const params = new URLSearchParams(window.location.search);

  return params.get('search') ?? '';
};

export const getDefaultSorting = (): Pick<GetCallsHistoryParams, 'sortBy' | 'order'> => {
  const params = new URLSearchParams(window.location.search);

  const rawOrder = params.get('order') as SortOrder | null;
  const rawSortBy = params.get('sortBy') as keyof CallHistory | null;

  return {
    order: rawOrder ?? SortOrder.descend,
    sortBy: rawSortBy ?? idColumnKey,
  };
};
